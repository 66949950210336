import React, { useState, useEffect } from "react";

import { queryCache } from "react-query";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiFlyoutFooter,
  EuiButton,
  EuiText,
  EuiTitle,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiFieldPassword,
  EuiFieldNumber,
} from "@elastic/eui";
import { useForm, Controller } from "react-hook-form";
import { baseApiUrl } from "../../envConfig";
import { useSelector, useDispatch } from "react-redux";

const AddUserFlatout = ({ setIsFlyoutVisible, restaurantId }) => {
  const editUser = useSelector((state) => state.user.editUser);
  let defaultValue = {};
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (editUser) {
      console.log("editUser", editUser);
      const user = editUser.user;
      defaultValue.name = user.name;
      defaultValue.email = user.email;
      defaultValue.mobile_number = user.mobile_number;
      reset({
        ...defaultValue,
      });
    }
  }, [editUser]);
  const { handleSubmit, errors, control, watch, reset } = useForm({
    defaultValues: {},
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const onSubmit = async (data) => {

    console.log(JSON.stringify(data));
    let user;
    setLoader(true);
    if (editUser) {
      user = await fetch(
        `${baseApiUrl}restaurants/${restaurantId}/users/${editUser.user.id}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          body: JSON.stringify({
            name: data.name,
            email: data.email,
            password: data.password,
            password_confirmation: data.confirm_password,
            mobile_number: data.mobile_number,
          }),
        }
      );
    } else {
      user = await fetch(`${baseApiUrl}restaurants/${restaurantId}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          password: data.password,
          password_confirmation: data.confirm_password,
          mobile_number: data.mobile_number,
        }),
      });
    }

    const userJson = await user.json();
    console.log(userJson);
    if (userJson.success === true) {
      setSuccessMessage(userJson.message);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
      reset();
      queryCache.invalidateQueries("getAllUser");
      setIsFlyoutVisible(false);
    } else {
      console.log(userJson);
      setErrorMessage(
        userJson.message ||
          userJson.errors.email ||
          userJson.errors.mobile_number
      );
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
      console.log(userJson.errors);
    }
    setLoader(false);
  };
  const handleCloseFlyout = () => {
    setIsFlyoutVisible(false);
    dispatch({
      type: "edit_user",
      payload: {
        editUser: null,
      },
    });
  };
  if (editUser && !watch("confirm_password") && !watch("password")) {
    delete errors["confirm_password"];
    delete errors["password"];
  }

  return (
    <EuiForm component="form">
      <EuiFlyout
        ownFocus
        onClose={() => handleCloseFlyout()}
        aria-labelledby="flyoutTitle"
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>Add User</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          {error && (
            <EuiText>
              <p style={{ color: "red", textAlign: "center" }}>
                {errorMessage}
              </p>
            </EuiText>
          )}
          {success && (
            <EuiText>
              <p style={{ color: "green", textAlign: "center" }}>
                {successMessage}
              </p>
            </EuiText>
          )}
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="name"
                isInvalid={errors.name}
                error={errors.name ? errors.name.message : ""}
              >
                <Controller
                  as={EuiFieldText}
                  name="name"
                  control={control}
                  rules={{
                    required: "Please enter name",
                    minLength: {
                      value: 3,
                      message: "minimum length should be 3",
                    },
                  }}
                  isInvalid={errors.name}
                  defaultValue=""
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="email"
                isInvalid={errors.email}
                error={errors.email ? errors.email.message : ""}
              >
                <Controller
                  as={EuiFieldText}
                  name="email"
                  control={control}
                  rules={{
                    required: "Please Enter your email",
                    minLength: {
                      value: 3,
                      message: "minimum length should be 3",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Please enter valid email ",
                    },
                  }}
                  isInvalid={errors.email}
                  defaultValue=""
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="password"
                isInvalid={errors.password}
                error={errors.password ? errors.password.message : ""}
              >
                <Controller
                  as={EuiFieldPassword}
                  name="password"
                  control={control}
                  rules={
                    editUser !== null &&
                    !watch("password") &&
                    !watch("confirm_password")
                      ? {}
                      : {
                          required: "Please Enter your password",
                          minLength: {
                            value: 6,
                            message: "minimum length should be 6",
                          },
                        }
                  }
                  isInvalid={errors.password}
                  defaultValue=""
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="confirm password"
                isInvalid={errors.confirm_password}
                error={
                  errors.confirm_password ? errors.confirm_password.message : ""
                }
              >
                <Controller
                  as={EuiFieldPassword}
                  name="confirm_password"
                  control={control}
                  defaultValue=""
                  rules={
                    editUser !== null &&
                    !watch("password") &&
                    !watch("confirm_password")
                      ? {}
                      : {
                          required: "Please Enter confirm password",
                          validate: (value) =>
                            value === watch("password") ||
                            "Passwords don't match.",
                        }
                  }
                  isInvalid={errors.confirm_password}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="mobile number"
                isInvalid={errors.mobile_number}
                error={errors.mobile_number ? errors.mobile_number.message : ""}
              >
                <Controller
                  as={EuiFieldNumber}
                  name="mobile_number"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Please Enter your mobile number",
                    minLength: {
                      value: 10,
                      message: " length should be 10",
                    },
                    maxLength: {
                      value: 10,
                      message: " length should be 10",
                    },
                  }}
                  isInvalid={errors.mobile_number}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem></EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="flexEnd">
            <EuiButton
              isLoading={loader}
              onClick={handleSubmit(onSubmit)}
              type="submit"
              fill
              style={{ margin: "10px 0px" }}
            >
              Save form
            </EuiButton>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiForm>
  );
};

export default AddUserFlatout;
